<template>
  <dialog-outline v-model="showDialog" :display-progress="displayProgress">
    <!-- dialog Icon -->
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>
    <!-- dialog Title -->
    <template v-slot:default>
      {{ dialogTitle }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <template v-slot:pre-context>
      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </template>

    <!-- context -->
    <template v-slot:context>
      <task-dialog-header :task="task"></task-dialog-header>

      <v-divider></v-divider>

      <!-- form (hookup any form validation if/when needed) -->
      <v-form>
        <v-container>
          <!-- Comments -->
          <v-textarea
            hide-details
            :label="commentLabel"
            v-model="comment"
            outlined
          ></v-textarea>

          <!-- Send Notification -->
          <v-checkbox
            dense
            hide-details
            v-model="sendNotification"
            :label="notificationLabel"
          ></v-checkbox>
        </v-container>
      </v-form>
    </template>

    <!-- Dialog Actions -->
    <template v-slot:actions>
      <base-tooltip-button @click="onOk">
        <template v-slot:default>
          {{ dialogOkCommandLabel }}
        </template>
        <template v-slot:toolTip>
          {{ dialogOkCommandTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-tooltip-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
        <template v-slot:toolTip>
          {{ dialogCancelCommandTooltip }}
        </template>
      </base-tooltip-button>
    </template>
  </dialog-outline>
</template>

<script>
// model
import {
  findUserTaskCommand,
  userTaskCommandType
} from "@/model/workflow/task/taskModel";

// mixins
import { userTaskAssignmentMixin } from "@/mixins/shared/workflow/task/userTaskAssignmentMixin";

export default {
  name: "ReleaseUserTaskDialog",
  mixins: [userTaskAssignmentMixin],
  computed: {
    /**
     * overwrite userTaskAssignmentMixin.command
     * @type {{type: number, label: string, icon: string, description: string}}
     */
    command() {
      return findUserTaskCommand(userTaskCommandType.release);
    }
  },
  methods: {
    /**
     * overwrite userTaskAssignmentMixin.doPerformAction()
     * @return {Promise<{type: string, message: string, outcome: string}|undefined>}
     */
    async doPerformAction() {
      return await this.performAction(this.comment, this.sendNotification);
    }
  }
};
</script>
